import {
  ROOT,
  GET_LIST_DEPARTURE_SCHEDULE,
  GET_DETAIL_DEPARTURE_SCHEDULE,
  UPDATE_STATUS_DEPARTURE_SCHEDULE,
  ADD_BUY_FUEL,
  ADD_PACKAGE,
  GET_LIST_PACKAGE,
} from "@/api/constant";
import { handleResponse } from "@/api/handle-response";
import { requestOptions } from "@/api/request-options";

function getListDepartureSchedule(data) {
  return fetch(ROOT + GET_LIST_DEPARTURE_SCHEDULE, requestOptions.post(data))
    .then(handleResponse)
    .then((data) => data);
}

function getDetailDepartureSchedule(data) {
  return fetch(ROOT + GET_DETAIL_DEPARTURE_SCHEDULE, requestOptions.post(data))
    .then(handleResponse)
    .then((data) => data);
}

function updateStatusDepartureSchedule(data) {
  return fetch(
    ROOT + UPDATE_STATUS_DEPARTURE_SCHEDULE,
    requestOptions.post(data)
  )
    .then(handleResponse)
    .then((data) => data);
}

function addBuyFuel(data) {
  return fetch(ROOT + ADD_BUY_FUEL, requestOptions.post(data))
    .then(handleResponse)
    .then((data) => data);
}

function addPackage(data) {
  return fetch(ROOT + ADD_PACKAGE, requestOptions.post(data))
    .then(handleResponse)
    .then((data) => data);
}

function getListPackage(data) {
  return fetch(ROOT + GET_LIST_PACKAGE, requestOptions.post(data))
    .then(handleResponse)
    .then((data) => data);
}

export const departureScheduleApi = {
  getListDepartureSchedule,
  getDetailDepartureSchedule,
  updateStatusDepartureSchedule,
  addBuyFuel,
  addPackage,
  getListPackage,
};
