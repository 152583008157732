import { departureScheduleApi } from "@/api/departureSchedule.js";
import { booking } from "@/api/booking.js";
import { carApi } from "@/api/car.js";
import dayjs from "dayjs";

import DatePick from "vue-date-pick";
import "vue-date-pick/dist/vueDatePick.css";

export default {
  components: {
    DatePick,
  },
  data: () => ({
    timeStart: dayjs().format("YYYY-MM-DD"),
    numberOfPassenger: 1,
    listDepartureSchedule: [],
    listNumOfAdult: [1, 2, 3, 4, 5],
    listNumOfChild: [0, 1, 2, 3],
    numOfAdult: 1,
    numOfChild: 0,
    departureScheduleId: -1,
    pickupPoint: "",
    dropPoint: "",
    isLoadingListDepartureSchedule: false,
    isLoadingUpdateBoooking: false,
    carType:"",
    carCapacity: null,
    carPrice: "",
    carDescription: "",
    carLicensePlates: ""
  }),
  async created() {
    // const loading = this.$vs.loading();
    // await this.getListDepartureSchedule(this.timeStart);
    // this.getUserAddress();
    await this.getDetailBooking();
    // loading.close();
  },
  watch: {
    timeStart(val) {
      this.getListDepartureSchedule(val);
    },
    departureScheduleId(val) {
      if (this.listDepartureSchedule.length) {
        this.departureScheduleName =
          this.listDepartureSchedule.find((x) => x.ID == val).from +
          " - " +
          this.listDepartureSchedule.find((x) => x.ID == val).to;
      }
      this.getDetailCar(this.listDepartureSchedule.find( x => x.ID == val).carId);
    },
  },
  methods: {
    disableDate(date) {
      const currentDate = new Date();
      let featureDate = new Date();
      featureDate.setDate(currentDate.getDate() + 7);

      return date < currentDate || date > featureDate;
    },
    async getListDepartureSchedule(starTime) {
      this.isLoadingListDepartureSchedule = true;
      let params = {
        starTime: starTime,
      };

      await departureScheduleApi
        .getListDepartureSchedule(params)
        .then((res) => {
          if (res.length) {
            this.listDepartureSchedule = res;
          } else {
            this.listDepartureSchedule = [
              {
                ID: -1,
                post_title: "Không có chuyến xe nào",
              },
            ];
          }
        });

      // Render lại select
      this.isLoadingListDepartureSchedule = false;
    },
    async getDetailBooking() {
      const loading = this.$vs.loading();
      const body = {
        id: this.$route.params.id,
      };
      await booking.getDetailBooking(body).then((res) => {
        this.timeStart = this.dayjs(res.acf.timeStart).format("YYYY-MM-DD");
        this.departureScheduleId = res.acf.departureScheduleId;
        this.numOfAdult = res.acf.numOfAdult;
        this.numOfChild = res.acf.numOfChild;
        this.pickupPoint = res.acf.pickupPoint;
        this.dropPoint = res.acf.dropPoint;
      });
      loading.close();
    },
    async updateBooking() {
      this.isLoadingUpdateBoooking = true;
      let body = {
        id: this.$route.params.id,
        numOfAdult: this.numOfAdult,
        numOfChild: this.numOfChild,
        pickupPoint: this.pickupPoint,
        dropPoint: this.dropPoint,
        departureScheduleId: this.departureScheduleId,
        departureScheduleName: this.departureScheduleName,
      };
      await booking.updateBooking(body).then((res) => {
        if (res.code == "SUCCESS") {
          this.$vs.notification({
            color: "success",
            position: "top-right",
            duration: "2000",
            title: "Cập nhật thành công !",
          });
        }
      });
      this.getDetailBooking();
      this.isLoadingUpdateBoooking = false;
    },
    getNameListDepartureSchedule(item) {
      return (
        this.dayjs(item.timeStart).format("HH:mm") + " - " + item.post_title
      );
    },
    checkRole(role) {
      let userRole = this.userData.roles;
      if (userRole) {
        var duplicates = role.filter(function (val) {
          return userRole.indexOf(val) != -1;
        });
        return duplicates.length;
      } else {
        return false;
      }
    },
    async getDetailCar(carId) {
      const body = {
        id: carId,
      };
      await carApi.getDetailCar(body).then((res) => {
        this.carType = res.acf.description;
        this.carCapacity = res.acf.capacity;
        this.carPrice = res.acf.price;
        this.carDescription = res.acf.description;
        this.carLicensePlates = res.acf.licensePlates;
      });
      
    },
  },
};
