import { ROOT, CREATE_NEW_CAR, GET_LIST_CAR, DELETE_CAR, GET_DETAIL_CAR } from "@/api/constant";
import { handleResponse } from "@/api/handle-response";
import { requestOptions } from "@/api/request-options";

function createNewCar(data) {
  return fetch(ROOT + CREATE_NEW_CAR, requestOptions.post(data))
    .then(handleResponse)
    .then((data) => data);
}

function getListCar(data) {
  return fetch(ROOT + GET_LIST_CAR, requestOptions.post(data))
    .then(handleResponse)
    .then((data) => data);
}

function getDetailCar(data) {
  return fetch(ROOT + GET_DETAIL_CAR, requestOptions.post(data))
    .then(handleResponse)
    .then((data) => data);
}

function deleteCar(data) {
  return fetch(ROOT + DELETE_CAR, requestOptions.post(data))
    .then(handleResponse)
    .then((data) => data);
}

export const carApi = {
  createNewCar,
  getListCar,
  deleteCar,
  getDetailCar
};
